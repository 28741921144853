/* LOGIN */
.login-container { height: 100vh; background: #dddddd url("/images/bg.default.jpg") no-repeat center center; background-size: cover; }
.login-overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #ffffff; opacity: 0.0; }
.login-box { background-color: rgba(0, 0, 0, 0.85); margin-top: 25vh; }
.login-box-inner { padding: 30px 50px; z-index: 1; }
.login-box h1 { margin-top: 10px; font-size: 44px; text-align: center; color: #ffffff; }
.login-box h2 { margin-top: 10px; font-size: 14px; text-align: center; color: #dddddd; }
.login-box p { color: #dddddd; }
.login-box form { min-height: 10em; }
.login-box .form-control { text-align: center; padding: 6px 10px!important; height: 40px; font-size: 14px; border: 1px solid #fff; }
.login-box .btn { text-align: center; padding: 6px 10px!important; height: 40px; }
.login-box .alert { width: 100%; }


/* LAYOUT */
.page-inner { height: calc(100vh - 82px); position: static !important; padding: 0 !important; background: #cccccc; overflow-y: scroll; }
#main-wrapper { padding: 5px !important; margin: 0 !important; height: 100%; }
#main-wrapper > .panel, .panel.full-height { height: 100%; margin-bottom: 0; }
#main-wrapper > .panel > .panel-body, .panel.full-height > .panel-body { height: 100%; }
.panel .panel-body { padding: 10px !important; }
.panel .panel-body.panel-padding { padding: 15px !important; }
.panel .panel-body .panel-head { width: 100%; float: left; padding-bottom: 3px; margin-bottom: 15px; border-bottom: 1px solid #eeeeee; }
.text-thin { font-weight: normal; }
.wrapped { white-space: pre-line; }
.compact-menu .menu.accordion-menu a span.menu-icon { margin-right: 6px!important; }
.compact-menu .menu.accordion-menu .arrow:before { content: "\f105"; font-family: "Font Awesome\ 5 Pro"; }
.compact-menu .menu.accordion-menu > li > a { border-right: 1px solid #42c7b1; }
.compact-menu .menu.accordion-menu > li:first-child > a { border-left: 1px solid #42c7b1; }

.compact-menu .menu.accordion-menu a { -webkit-transition: all 300ms;-moz-transition: all 300ms;-ms-transition: all 300ms;-o-transition: all 300ms;transition: all 300ms; }
.compact-menu .menu.accordion-menu .sub-menu li:hover > a { background-color: #22baa0!important; padding-left: 20px!important; }
.compact-menu .menu.accordion-menu .sub-menu li:hover .sub-menu { background-color: #22baa0!important; padding: 2px!important; }
.compact-menu .menu.accordion-menu .sub-menu li:hover .sub-menu li a { padding: 9px 15px 9px 15px!important; }
.compact-menu .menu.accordion-menu .sub-menu li:hover .sub-menu li:hover a { background-color: #1db198!important; padding-left: 20px!important; }


/* COLORS */
body { color: #222222; }
.text-gray-lightest { color: #dddddd; }
.text-gray-lighter { color: #bbbbbb; }
.text-gray-light { color: #999999; }
.text-gray { color: #777777; }
.text-gray-dark { color: #444444; }
.text-gray-darkest { color: #222222; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover.text-gray { color: #222222; }
a:hover .text-gray { color: #222222; }
a:focus.text-gray { color: #222222; }
a:focus .text-gray { color: #222222; }
button.text-gray:hover { color: #222222; }

.text-orange, .text-orange:focus { color: #f69833; }
.text-orange:hover { color: #a76722; }

.text-danger, .text-danger:focus { color: #f25656; }
.text-danger:hover { color: #843534; }

.text-success, .text-success:focus { color: #22BAA0; }


/* ALERTS */
.alert { border: 1px solid #eeeeee; }

.alert-default { border-color: #dddddd!important; background-color: #F9F9F9; }
.alert-default * { border-color: #dddddd!important; }
.alert-default .Select-arrow { border-color: #999 transparent transparent!important; }
.alert-default .text-gray { color: #777777!important; }

.alert-info { border-color: #A3C9E3!important; }
.alert-info *, .alert-info .table > tbody > tr > td { border-color: #A3C9E3!important; }
.alert-info .table > tbody > tr > td { border-left-color: #c3daea!important; }
.alert-info .text-gray { color: #31708f!important; }

.alert-success { border-color: #a8caa9!important; color: #105011; }
.alert-success *, .alert-success .table > tbody > tr > td { border-color: #a8caa9!important; }
.alert-success .table > tbody > tr > td { border-left-color: #cee8cf!important; }
.alert-success .text-gray { color: #3c763d!important; }

.alert-danger { border-color: #e0adac!important; }
.alert-danger *, .alert-danger .table > tbody > tr > td { border-color: #e0adac!important; }
.alert-danger .table > tbody > tr > td { border-left-color: #e6c9c8!important; }
.alert-danger .text-gray { color: #a94442!important; }

.alert-warning { border-color: #d8c8ad!important; }
.alert-warning *, .alert-warning .table > tbody > tr > td { border-color: #d8c8ad!important; }
.alert-warning .table > tbody > tr > td { border-left-color: #efe0c8!important; }
.alert-warning .text-gray { color: #8a6d3b!important; }


/* BUTTONS */
button { background: transparent; border: 0; }
button:focus { outline: none; }
button.table-sort { padding: 2px; color: #cccccc; }
button.table-sort:first-child { margin-left: 4px; }
button.table-sort.active { color: #22baa0; }
button.table-sort:hover { color: #22baa0; }
.no-text-decoration:hover { text-decoration: none; }
.btn.fas, .btn.far { margin-top: 1px; }
.btn-default, .btn-default:hover { border: 1px solid #bbb; }
.btn-dark { background-color: #555; color: #fff; }
.btn-dark:hover { background-color: #444; color: #fff; }
.btn-dark:focus { color: #fff; }
.btn-success[disabled] { background-color: #22BAA0; border-color: #22BAA0; }


/* ROUNDED BUTTONS */
span.btn-rounded { padding: 0 10px; }
.btn-gray { background-color: #999999; color: #ffffff; border: 1px solid #999999; }
.btn.btn-rounded { text-align: center; }
.btn.btn-rounded i { margin: -5px; }


/* NOTIFICATIONS */
.notifications-tc { width: 70%!important; margin-left: -35%!important; text-align: center!important; overflow: hidden!important; padding: 0!important; height: 40px!important; }
.notification { padding: 5px 15px !important; margin: 5px 5px 0 5px!important; border: none !important; box-shadow: none!important; width: auto!important; display: inline-block!important; line-height: 20px!important; height: auto!important; border-radius: 16px!important; }
.notification-dismiss { display: none!important; }
.notification h4 { display: inline-block!important; margin-right: 5px!important; margin-bottom: 0!important; line-height: 20px!important; font-weight: 700!important; }
.notification-message { display: inline-block!important; }

.notification-success, .notification-success h4 { background-color: #22BAA0!important; color: #ffffff!important; }
.notification-success h4:before { content: "\f00c"; font: normal normal normal 14px/1 Font Awesome\ 5 Pro; margin-right: 5px; }

.notification-error, .notification-error h4 { background-color: #f25656!important; color: #ffffff!important; }
.notification-error h4:before { content: "\f071"; font: normal normal normal 14px/1 Font Awesome\ 5 Pro; margin-right: 5px; }

.notification-warning, .notification-warning h4 { background-color: #f6d433!important; color: #ffffff!important; }
.notification-warning h4:before { content: "\f071"; font: normal normal normal 14px/1 Font Awesome\ 5 Pro; margin-right: 5px; }

.notification-info, .notification-info h4 { background-color: #12AFCB!important; color: #ffffff!important; }
.notification-info h4:before { content: "\f129"; font: normal normal normal 14px/1 Font Awesome\ 5 Pro; margin-right: 5px; }

.mailbox-content table tbody tr td { cursor: default !important; }


/* FORMS */
label { color: #777777; white-space: nowrap; }
.no-label { padding-top: 25px; }
input { color: #000000!important; }
textarea { resize: none!important; }
textarea.resizable { resize: vertical!important; }
.form-group { margin-bottom: 10px; }
.form-group label { margin-bottom: 2px; line-height: 18px; }
.form-group.has-error label { color: #a94442; }
.form-group.has-error input { border-color: #a94442!important; }
.form-group.has-error select { border-color: #a94442!important; }
.form-group.has-error > span { border-color: #a94442!important; }
.form-group.has-error .Select-control { border-color: #a94442!important; }
.form-group.has-error.has-error-fixed input { border-color: #3c763d!important; }
.form-group.has-error.has-error-fixed select { border-color: #3c763d!important; }
.form-group.has-error.has-error-fixed > span { border-color: #3c763d!important; }
.form-group.has-error .alert { padding: 5px 8px; font-size: 11px; display: inline-block; margin-bottom: 0; }
.form-group-bigger .form-control { height: 40px; font-weight: bold; line-height: 30px; font-size: 20px; }
.form-label { padding-top: 6px; color: #777777; }

.form-control { padding: 4px 8px !important; height: 30px; font-weight: normal; color: #000000!important; line-height: 20px; }
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control { background-color: #f7f7f7; }

.input-group .btn { padding: 5px 12px; }
.input-group-btn .btn i { margin-right: -13px; }
.input-group .form-control { z-index: 0; }
.input-unit { display: inline-block; padding: 5px 8px !important; margin-top: -5px; text-align: center; height: 30px; width: 100%; line-height: 20px; border: 1px solid #dce1e4; border-left: none; background-color: #f7f7f7; }
.input-readonly { background-color: #f0f0f0; }

.btns-form { border-top: 1px solid #eeeeee; padding-top: 15px; }

.cl-left { clear: left; }

.btn.disabled, .btn[disabled], fieldset[disabled] .btn { opacity: 0.4; }

.form-part { padding: 0 10px; margin-bottom: 10px; background-color: #f9f9f9; border: 1px solid #dce1e4; }
.form-part-transport { padding: 7px 10px; margin-top: 5px; margin-bottom: 0; }
.form-part-transport .btn-xs { margin-top: -6px; margin-bottom: -3px; }

.compact-checkboxes .checkbox { margin: 5px 0; }


/* LIST */
h4 { float: left; margin-bottom: 10px; }

.btns-list { float: right; margin-bottom: 10px; }
.btns-right { float: right; }
.btn { padding: 4px 12px; height: 30px; }
.btn i { width: 30px; height: 30px; margin: -5px -13px; margin-right: 12px; line-height: 32px; }
.btn .caret { margin-left: 5px; margin-top: -2px; }
.btn-xs { padding: 1px 5px; font-size: 12px; line-height: 1.5; height: 22px; }


/* TABLES */
.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th, .table td { padding: 6px 10px !important; }
.table > tbody > tr > td, .table > tfoot > tr > td, .table > thead > tr > td, .table td { border-left: 1px solid #eeeeee!important; }
.table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child, .table > thead > tr > td:last-child, .table td:last-child { border-right: 1px solid #eeeeee!important; }
.table > tbody > tr > td:first-child { border-left: 1px solid #dddddd; }
.table > tbody > tr > td:last-child { border-right: 1px solid #dddddd; }
.table > tbody > tr:last-child > td { border-bottom: 1px solid #dddddd; }
.table > thead > tr.filters > th { padding: 1px 5px !important; background-color: #dddddd; border-left: 1px solid #dddddd; border-right: 1px solid #dddddd; vertical-align: top; }
.table > thead > tr.filters > th input, .table > thead > tr.filters > th select { border: 1px solid #bdc5c9; padding: 3px 5px!important; height: 26px; max-width: 500px; }

.modal .table > tbody > tr > td:first-child { border-left: none!important; }
.modal .table > tbody > tr > td:last-child { border-right: none!important; }

.table-container { overflow-y: auto; min-width: 100%; height: calc(100% - 90px); }
.table-container.table-container-static { height: auto; }
.table-container-no-pagination { height: calc(100% - 55px); }
.table.table-fixed-header { table-layout: fixed; margin-bottom: 0; height: 100%; width: auto; min-width: 100%; max-width: none; }
.table.table-fixed-header thead tr { display: block; position: relative; }
.table.table-fixed-header tbody { display: block; overflow-x: auto; width: 100%; height: 100%; }
.modal .table.table-fixed-header { height: calc(70vh - 45px); }
.modal .table.table-fixed-header.full-height { height: auto; }

.table.table-map-vehicles > tbody > tr > td { padding: 0!important; }
.table.table-map-vehicles tr td { position: relative; height: 50px; cursor: pointer; }
.table.table-map-vehicles tr td.selected { background-color: #d3e6f0; }
.table.table-map-vehicles tr td > a { display: block; width: 100%; height: 100%; padding: 7px 10px 6px !important; text-decoration: none; }
.table.table-map-vehicles tr td .vehicle { width: 90px; height: 30px; float: left; }
.table.table-map-vehicles tr td .driver { margin-left: 10px; font-size: 11px; color: #222222; line-height: 18px; }
.table.table-map-vehicles tr td .vehicle-state { position: absolute; top: 5px; right: 5px; font-size: 11px; }
.table.table-map-vehicles tr td .trailer-state { position: absolute; top: 26px; right: 5px; color: #777777; font-size: 11px; }

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th, .table td { padding: 5px 8px !important; font-size: 12px; line-height: 16px; }
.table > tbody > tr.tr-dark { background-color: #f9f9f9; }
.table > thead > tr > th.text-center { padding-left: 0!important;; padding-right: 0!important; overflow: hidden; }

.table-compact > tbody > tr > td, .table-compact > tbody > tr > th, .table-compact > tfoot > tr > td, .table-compact > tfoot > tr > th, .table-compact > thead > tr > td, .table-compact > thead > tr > th, .table-compact td { padding: 5px 3px !important; }
.table-compact > tbody > tr > td.text-small { font-size: 10px; }

.table-no-border > tbody > tr > td:first-child { border-left: 0; }
.table-no-border > tbody > tr > td:last-child { border-right: 0; }
.table-any-border > tbody > tr > td { border: 0!important; }
.table > tbody > tr > td.no-p { padding: 0!important; }
.table > tbody > tr > td.no-h-p { padding-left: 0!important; padding-right: 0!important; }
.table > tbody > tr.tr-border-top > td { border-top: 1px solid #eeeeee!important; }

.table-striped > tbody > tr:nth-of-type(odd) > td.bg-darker { background-color: #eeeeee; }
.table-striped > tbody > tr:nth-of-type(even) > td.bg-darker { background-color: #f9f9f9; }
.table-striped > tbody > tr.b-top > td { border-top: 2px solid #ddd; }

.table-striped > tbody > tr.is-deleted > td { background-color: rgba(255, 0, 0, 0.075); }

.table-striped > tbody > tr.transparent { background-color: transparent; }

.table tr.row-selected td { background-color: #d3e6f0; }
.table tr.row-selectable td { cursor: pointer; }

.table tr td.td-clickable { text-decoration: underline; }
.table tr td.td-clickable:hover { background-color: #d3e6f0; cursor: pointer; }
.table tr td.td-changed { background-color: rgba(255, 255, 0, 0.3); }
.table tr td.td-danger { color: #c70000; }
.table tr.tr-empty { height: 30px; background-color: transparent!important; }

.table tr.row-sum td { background-color: rgba(255, 255, 0, 0.1); }
.table tr.row-sum td.td-clickable:hover { background-color: #d3e6f0!important; }

.table .label { float: left; padding: 4px; }
.table .action-icon { margin-top: 1px; }
.table .btn.far, .table .btn.fas { width: 37px; }

.table-white td { background-color: #ffffff; }

.changed { color: #c70000; }


/* PAGINATION */
.pagination { margin: 10px 0 0 !important; float: right; }
.pagination li { cursor: pointer; }


/* DIMENSIONS */
.w-10 { width: 10px; max-width: 100%; }
.w-20 { width: 20px; max-width: 100%; }
.w-40 { width: 40px; max-width: 100%; }
.w-60 { width: 60px; max-width: 100%; }
.w-80 { width: 80px; max-width: 100%; }
.w-90 { width: 90px; max-width: 100%; }
.w-100 { width: 100px; max-width: 100%; }
.w-120 { width: 120px; max-width: 100%; }
.w-140 { width: 140px; max-width: 100%; }
.w-160 { width: 160px; max-width: 100%; }
.w-180 { width: 180px; max-width: 100%; }
.w-200 { width: 200px; max-width: 100%; }
.w-220 { width: 220px; max-width: 100%; }
.w-240 { width: 240px; max-width: 100%; }
.w-260 { width: 260px; max-width: 100%; }
.w-280 { width: 280px; max-width: 100%; }
.w-300 { width: 300px; max-width: 100%; }
.w-400 { width: 400px; max-width: 100%; }
.w-500 { width: 500px; max-width: 100%; }
.w-600 { width: 600px; max-width: 100%; }
.w-700 { width: 700px; max-width: 100%; }
.w-800 { width: 800px; max-width: 100%; }

.wp-10 { width: 10%; max-width: 10%; }
.wp-20 { width: 20%; max-width: 20%; }
.wp-30 { width: 30%; max-width: 30%; }
.wp-40 { width: 40%; max-width: 40%; }
.wp-50 { width: 50%; max-width: 50%; }
.wp-60 { width: 60%; max-width: 60%; }
.wp-70 { width: 70%; max-width: 70%; }
.wp-80 { width: 80%; max-width: 80%; }
.wp-90 { width: 90%; max-width: 90%; }
.wp-100 { width: 100%; max-width: 100%; }

.h-100 { height: 100px !important; max-height: 100%; }
.h-200 { height: 200px !important; max-height: 100%; }
.h-300 { height: 300px !important; max-height: 100%; }
.h-400 { height: 400px !important; max-height: 100%; }
.h-500 { height: 500px !important; max-height: 100%; }
.h-600 { height: 600px !important; max-height: 100%; }
.h-700 { height: 700px !important; max-height: 100%; }
.h-800 { height: 800px !important; max-height: 100%; }
.h-900 { height: 900px !important; max-height: 100%; }
.h-1000 { height: 1000px !important; max-height: 100%; }
.h-1500 { height: 1500px !important; max-height: 100%; }
.h-2000 { height: 2000px !important; max-height: 100%; }

.hp-10 { height: 10% !important; max-height: 10%; }
.hp-20 { height: 20% !important; max-height: 20%; }
.hp-30 { height: 30% !important; max-height: 30%; }
.hp-40 { height: 40% !important; max-height: 40%; }
.hp-50 { height: 50% !important; max-height: 50%; }
.hp-60 { height: 60% !important; max-height: 60%; }
.hp-70 { height: 70% !important; max-height: 70%; }
.hp-80 { height: 80% !important; max-height: 80%; }
.hp-90 { height: 90% !important; max-height: 90%; }
.hp-100 { height: 100% !important; max-height: 100%; }

.mh-50 { min-height: 50px; }
.mh-100 { min-height: 100px; }
.mh-500 { min-height: 500px; }

.table .w-20 { width: 20px; min-width: 20px; max-width: 20px; }
.table .w-33 { width: 33px; min-width: 33px; max-width: 33px; }
.table .w-30 { width: 30px; min-width: 30px; max-width: 30px; }
.table .w-40 { width: 40px; min-width: 40px; max-width: 40px; }
.table .w-50 { width: 50px; min-width: 50px; max-width: 50px; }
.table .w-60 { width: 60px; min-width: 60px; max-width: 60px; }
.table .w-70 { width: 70px; min-width: 70px; max-width: 70px; }
.table .w-80 { width: 80px; min-width: 80px; max-width: 80px; }
.table .w-90 { width: 90px; min-width: 90px; max-width: 90px; }
.table .w-100 { width: 100px; min-width: 100px; max-width: 100px; }
.table .w-110 { width: 110px; min-width: 110px; max-width: 110px; }
.table .w-120 { width: 120px; min-width: 120px; max-width: 120px; }
.table .w-140 { width: 140px; min-width: 140px; max-width: 140px; }
.table .w-160 { width: 160px; min-width: 160px; max-width: 160px; }
.table .w-180 { width: 180px; min-width: 180px; max-width: 180px; }
.table .w-190 { width: 190px; min-width: 190px; max-width: 190px; }
.table .w-200 { width: 200px; min-width: 200px; max-width: 200px; }
.table .w-220 { width: 220px; min-width: 220px; max-width: 220px; }
.table .w-240 { width: 240px; min-width: 240px; max-width: 240px; }
.table .w-260 { width: 260px; min-width: 260px; max-width: 260px; }
.table .w-270 { width: 270px; min-width: 270px; max-width: 270px; }
.table .w-280 { width: 280px; min-width: 280px; max-width: 280px; }
.table .w-300 { width: 300px; min-width: 300px; max-width: 300px; }
.table .w-310 { width: 310px; min-width: 310px; max-width: 310px; }
.table .w-320 { width: 320px; min-width: 320px; max-width: 320px; }
.table .w-340 { width: 340px; min-width: 340px; max-width: 340px; }
.table .w-350 { width: 350px; min-width: 350px; max-width: 350px; }
.table .w-360 { width: 360px; min-width: 360px; max-width: 360px; }
.table .w-380 { width: 380px; min-width: 380px; max-width: 380px; }
.table .w-400 { width: 400px; min-width: 400px; max-width: 400px; }
.table .w-420 { width: 420px; min-width: 420px; max-width: 420px; }
.table .w-440 { width: 440px; min-width: 440px; max-width: 440px; }
.table .w-460 { width: 460px; min-width: 460px; max-width: 460px; }
.table .w-500 { width: 500px; min-width: 500px; max-width: 500px; }
.table .w-540 { width: 540px; min-width: 540px; max-width: 540px; }
.table .w-600 { width: 600px; min-width: 600px; max-width: 600px; }
.table .w-700 { width: 700px; min-width: 700px; max-width: 700px; }
.table .w-720 { width: 720px; min-width: 720px; max-width: 720px; }

.table .w-2000 { width: 5000px; min-width: 350px; }
.table .w-max { width: 5000px; min-width: 150px; }

.table .wm-100 { min-width: 100px; }
.table .wm-120 { min-width: 120px; }
.table .wm-140 { min-width: 140px; }
.table .wm-200 { min-width: 200px; }
.table .wm-250 { min-width: 250px; }
.table .wm-300 { min-width: 300px; }
.table .wm-360 { min-width: 360px; }
.table .wm-400 { min-width: 400px; }

.table-transports tr.filters .checkbox { margin: 0; }
.table-transports tr.filters .checkbox input { height: 16px; margin: 7px 0 0 -15px; }

.table input[type="radio"], .table input[type="checkbox"] { margin-top: 0; }

.table-hover > tbody > tr:hover { background-color: #e9e9e9; }
.table-hover > tbody > tr:hover > td { border-color: #dddddd!important; }

.table-buttons a { width: 18px; text-align: center; display: inline-block; }
.table-buttons button { width: 18px; padding: 1px 0 2px; }
.table-buttons .fas, .table-buttons .far, .table-buttons .fal { font-size: 14px; }
.table .fa-file-image, .table .far.fa-check, .table .fa-times { font-size: 14px; }

.table > thead > tr > th.th-spanned { background-color: #fafafa; border-top: 1px solid #dddddd!important; border-left: 1px solid #dddddd!important; border-right: 1px solid #dddddd!important; }
.table > thead > tr.th-bordered th { border-left: 1px solid #eeeeee!important; border-right: 1px solid #eeeeee!important; }

.table td.vertical-middle { vertical-align: middle; }


/* MARGINS & PADDINGS */
.m-b-no { margin: 0!important; }
.p-t-5 { padding-top: 5px; }
.p-t-10 { padding-top: 10px; }
.p-t-15 { padding-top: 15px; }

.opacity-25 { opacity: 0.25; }
.opacity-50 { opacity: 0.5; }


/* PLACES AUTOCOMPLETE */
#PlacesAutocomplete__root input { padding: 4px 8px !important; height: 30px!important; border: 1px solid #dce1e4!important; color: #555!important; }
#PlacesAutocomplete__autocomplete-container { z-index: 100; border: 1px solid #dce1e4!important; }
#PlacesAutocomplete__autocomplete-container > div { padding: 4px 8px !important; height: 30px!important; color: #555!important; }
#PlacesAutocomplete__google-logo { padding: 4px 8px !important; height: 30px!important; }

/* AUTOCOLPLETE */
.autosuggest__container { position: relative; color: #555 !important}
.autosuggest__suggestions-container { display: none; background-color: #fff;}
.autosuggest__suggestions-container--open { display: block; position: absolute; top: 100%; width: 100%; z-index: 100; border: 1px solid #dce1e4!important; }
.autosuggest__suggestions-list { margin: 0; padding: 0; list-style-type: none; }
.autosuggest__suggestion { padding: 4px 8px !important; height: 30px!important; color: #555!important; }
.autosuggest__suggestion--highlighted { background-color: #f6f6f6; cursor: pointer;}

/* TRANSPORT */
.transport-customers {  }
.transport-customers .transport-customers-row { margin-top: 20px; }

.transport-waypoints { pointer-events: auto !important; }

.transport-waypoints .panel { box-shadow: none; margin-bottom: 3px; }
.transport-waypoints .panel .panel-heading { padding: 8px 12px; height: auto; font-size: 13px; }
.transport-waypoints .panel .panel-heading .sort-handle { cursor: move; }
.transport-waypoints .panel .panel-heading .type { width: 200px; display: inline-block; }
.transport-waypoints .panel .panel-heading .state { width: 20px; display: inline-block; }
.transport-waypoints .panel .panel-heading .fas, .transport-waypoints .panel .panel-heading .far { color: #ffffff; font-size: 14px; vertical-align: 0; }
.transport-waypoints .panel .panel-heading .fa-spin.fa-cog { transform-origin: 50% 47.5%; }
.transport-waypoints .panel .panel-heading { color: #ffffff; }
.transport-waypoints .panel .panel-heading a { color: #ffffff; }
.transport-waypoints .panel .panel-heading a:hover { text-decoration: none; }
.transport-waypoints .panel .panel-body { border: 1px solid #e7e7e7; padding: 10px 15px; }
.transport-waypoints .panel .panel-body .times .left { display: inline-block; width: 80px; }
.transport-waypoints .panel .panel-body .cargo span { display: inline-block; width: 100%; text-align: right; }
.transport-waypoints .panel .panel-body .cargo .left { display: inline-block; width: 150px; float: left; text-align: left; }
.transport-waypoints .panel .panel-body .cargo .right { width: auto; }

p.times .left { display: inline-block; width: 80px; }

.transport-waypoints .panel.panel-predeparture-check .panel-body { border-color: #333333; }
.transport-waypoints .panel.panel-predeparture-check .panel-heading { background-color: #333333; }

.transport-waypoints .panel.panel-start .panel-body { border-color: #999999; }
.transport-waypoints .panel.panel-start .panel-heading { background-color: #999999; }
.transport-waypoints .panel.panel-start.panel-start-error .panel-heading { background-color: #f25656; }

.transport-waypoints .panel.panel-loading .panel-body { border-color: #7a6fbe; }
.transport-waypoints .panel.panel-loading .panel-heading { background-color: #7a6fbe; }

.transport-waypoints .panel.panel-unloading .panel-body { border-color: #22BAA0; }
.transport-waypoints .panel.panel-unloading .panel-heading { background-color: #22BAA0; }

.transport-waypoints .panel.panel-waypoint .panel-body { border-color: #dddddd; }
.transport-waypoints .panel.panel-waypoint .panel-heading { background-color: #dddddd; color: #444444!important; }
.transport-waypoints .panel.panel-waypoint .panel-heading a { color: #444444!important; }
.transport-waypoints .panel.panel-waypoint .panel-heading .fas, .transport-waypoints .panel.panel-waypoint .panel-heading .far { color: #444444!important; }

.transport-waypoints .panel.panel-refueling .panel-body { border-color: #e0c12e; }
.transport-waypoints .panel.panel-refueling .panel-heading { background-color: #e0c12e; }

.transport-waypoints .panel.panel-parking .panel-body { border-color: #12AFCB; }
.transport-waypoints .panel.panel-parking .panel-heading { background-color: #12AFCB; }

.transport-waypoints .panel.panel-servis .panel-body { border-color: #f25656; }
.transport-waypoints .panel.panel-servis .panel-heading { background-color: #f25656; }

.transport-waypoints .panel.panel-trailer .panel-body { border-color: #777777; }
.transport-waypoints .panel.panel-trailer .panel-heading { background-color: #777777; }

.transport-routes h1 { margin-top: 10px; margin-bottom: 5px; }
.transport-routes .text-list { line-height: 22px; }

.transport-log-row { margin: 0; padding: 3px 0 1px 0; border-top: 1px solid #dddddd; }

.transport_part { padding: 2px 10px; margin-top: 3px; margin-bottom: 3px; border: 1px solid #cccccc; border-radius: 50px; width: 703px; float: left; font-style: italic; }
.transport_part:first-child { margin-top: -2px; }
.transport_part:last-child { margin-bottom: 0; }

.transport-point-loading { width: 340px; float: left; }
.transport-point-unloading { width: 340px; float: right; }

/* TRACING */
.tracing-list .sort-handle { cursor: move; }
.routing-map-info { position: absolute; top: 1px; left: 1px; z-index: 1; background: white; font-size: 14px; padding: 5px; }
.routing-map-info .spinner { width: 14px; height: 14px; margin: auto; position: absolute;}
.routing-map-info .info { margin-left: 20px; }
.tracing-list div.tracing-list-item { position: relative; margin-bottom: 18px;}
.tracing-list div.tracing-list-item .tracing-list-add { position: absolute; text-align: center; width: 33px; bottom: -18px; }
.H_ib.vehicle-infobubble .H_ib_body { width: 280px; }

.cols-10-90 .left { display: inline-block; width: 10%; }
.cols-20-80 .left { display: inline-block; width: 20%; }
.cols-30-70 .left { display: inline-block; width: 30%; }
.cols-40-60 .left { display: inline-block; width: 40%; }
.cols-50-50 .left { display: inline-block; width: 50%; }
.cols-60-40 .left { display: inline-block; width: 60%; }
.cols-70-30 .left { display: inline-block; width: 70%; }
.cols-80-20 .left { display: inline-block; width: 80%; }
.cols-90-10 .left { display: inline-block; width: 90%; }

.line-p { line-height: 22px; }


/* SELECTS */
.Select { outline: none; }
.Select-control { height: 30px; border-radius: 0; border-color: #dce1e4!important; box-shadow: none!important; }
.Select-control:hover { box-shadow: none; }
.Select-input { height: 28px; padding-left: 8px; }
.Select-input > input { padding: 5px 0; }
.Select-placeholder, .Select--single > .Select-control .Select-value { line-height: 28px; padding-left: 8px; }
.Select-menu-outer { border-radius: 0; }
.Select-menu { line-height: 12px; }
.Select-menu-option { height: 28px; }


/* MODALS */
.modal { z-index: 1000; }
.modal-backdrop { z-index: 1000; }
.modal-dialog { width: 95%; max-width: 1200px; }
.modal-lg { max-width: 1600px; }
.modal-md { max-width: 800px; }
.modal-sm { max-width: 500px; }
.modal-size-auto .modal-dialog { max-width: none; }
.modal-size-xl .modal-dialog { max-width: 1800px; }
.modal-size-lg .modal-dialog { max-width: 1600px; }
.modal-size-md-2 .modal-dialog { max-width: 1400px; }
.modal-size-md .modal-dialog { max-width: 800px; }
.modal-size-sm-2 .modal-dialog { max-width: 650px; }
.modal-size-sm .modal-dialog { max-width: 500px; }
.modal-level-2 .modal-dialog { margin: 60px auto; }

.modal .page-inner { height: calc(100vh - 150px); }

/* PERIODPICKER */
.period-picker .modal-content { background-color: #f9f9f9; }
.period-picker-item a, .period-picker-item a:hover, .period-picker-item a:focus  { border: none !important; background: inherit !important; }
.period-picker-menu ul { border-bottom: none; }
.period-picker-menu ul .period-picker-item:first-child { border-top: 1px solid #f9f9f9; }
.period-picker-menu ul .period-picker-item:first-child.active { border-top-color: #DDDDDD; }
.period-picker-item { border: 1px solid #DDDDDD; border-width: 0px 1px 1px 0px; margin-bottom: -2px !important; }
.period-picker-item.active { border-right-color: #FFFFFF; border-left-width: 1px; background-color: #FFFFFF; z-index: 1;}
.period-picker-menu { padding-right: 0px; margin-right: -1px; }
.period-picker-body { border: 1px solid #DDDDDD; padding-right: 0px; padding-left: 0px; }
.period-picker-body .tab-content { height: 340px; }

/* DATEPICKER */
.react-datepicker-wrapper { width: 100%; }
.react-datepicker__input-container { width: 100%; }
.react-datepicker__input-container input { width: 100%; padding: 4px 8px !important; height: 30px; border: 1px solid #dce1e4; outline: none; font-weight: normal; font-size: 13px; }
.react-datepicker__input-container input:focus { border-color: #bdc5c9; }
.react-datepicker__input-container:after { content: '\f073'; font: normal normal normal 14px/1 Font Awesome\ 5 Pro; font-weight: 300; position: absolute; top: 7px; right: 8px; color: #cccccc; }
.react-datepicker { border-color: #bdc5c9; font-size: 12px; border-radius: 0; }
.react-datepicker__header { border-color: #bdc5c9; background-color: #eeeeee; }
.react-datepicker__current-month, .react-datepicker-time__header { font-size: 12px; font-weight: normal; }
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) { right: 67px; }
.react-datepicker__day-name { font-size: 11px; width: 26px; margin: 1px; color: #999999; }
.react-datepicker__month { margin: 1px; }
.react-datepicker__day { border: 1px solid #eee; height: 26px; width: 26px; line-height: 26px; text-align: center; margin: 1px; background-color: #ffffff; font-weight: normal; }
.react-datepicker__day:hover { border-radius: 0; background-color: #eeeeee; }
.react-datepicker__day--selected { background-color: #22BAA0; border-radius: 0; border-color: #22BAA0; }
.react-datepicker__day--selected:hover { background-color: #22BAA0; }
.react-datepicker__day--keyboard-selected { color: #222222; }
.react-datepicker__day--outside-month { color: #ccc; }
.react-datepicker__day--today { font-weight: bold; }

.react-datepicker__time-container { border-color: #bdc5c9; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box { width: 90px; }
.react-datepicker__header--time { height: 45px; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { padding-left: 0; padding-right: 0; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item { padding: 6px 15px 7px; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected { background-color: #22BAA0; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover { background-color: #22BAA0; }

.react-datepicker-with-time .react-datepicker { min-width: 291px; }

.filters .react-datepicker__input-container input { line-height: 18px; }
.filters .react-datepicker__input-container:after { content: none; }

/* COLORPICKER */
.swatches-picker.color-picker div div div { overflow-y: hidden !important; }

.relative { position: relative; }
.transport-state-icon { width: 3px; height: 12px; float: left; margin-top: 2px; margin-right: 3px; }
.transport-point-icons { position: absolute; top: 0; right: 0; width: 80px; }
.transport-point-icons-before { right: 713px; }
.transport-point-icons-between { right: 348px; }
.transport-point-icons-after { right: 0; }
.transport-point-icons > div { float: right; }
.transport-point-type-icon { font-size: 10px; float: left; padding: 1px; margin-right: 2px; margin-top: 1px; }
.transport-point-type-icon.fa-spin.fa-cog { transform-origin: 50% 52%; }

.board-legend .transport-state-icon { float: none; margin-right: 0; display: inline-block; margin-bottom: -3px; margin-top: 0; }
.transport-state-icon-1 { background-color: #f6d433; }
.transport-state-icon-2 { background-color: #12afcb; }
.transport-state-icon-3 { background-color: #22baa0; }
.transport-state-icon-4 { background-color: #7a6fbe; }
.transport-state-icon-5 { background-color: #f25656; }
.transport-state-icon-6 { background-color: #aaaaaa; }
.transport-state-icon-7 { background-color: #636363; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus { color: #bbbbbb!important; }
.dropdown-menu li a i { margin-right: 5px!important; width: 16px; text-align: center; }

.table .btn-group, .btn-group-vertical { margin-top: 1px; }

.navbar-nav li .dropdown button { padding: 10px 18px; height: 40px; border-color: #ffffff; color: #5f5f5f; }
.navbar-nav li .dropdown.open .dropdown-menu { margin-top: 15px!important; }

hr.tiny { margin: 10px 0; }

/* open-sans-300 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/open-sans-v13-latin/open-sans-v13-latin-300.eot'); /* IE9 Compat Modes */
	src: local('Open Sans Light'), local('OpenSans-Light'),
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Open Sans'), local('OpenSans'),
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* loading overlay */
.loading-overlay { position: relative; height: 100%; width: 100%; overflow: overlay; }
.loading-overlay-inner { position: absolute; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.9); z-index: 1000; }
.loading-overlay-contents { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.loading-overlay-contents .sk-spinner { margin: 0 auto; margin-bottom: 20px; }
.loading-overlay-dark .loading-overlay-inner { background-color: rgba(0, 0, 0, 0.9); color: #ffffff; }
.loading-overlay-transparent .loading-overlay-inner { background-color: transparent; color: #ffffff; }

/* spinner */
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  margin: 20px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(34, 186, 160, 0.2);
  border-right: 1.1em solid rgba(34, 186, 160, 0.2);
  border-bottom: 1.1em solid rgba(34, 186, 160, 0.2);
  border-left: 1.1em solid rgba(34, 186, 160, 1.0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Dispatcher board */
h4 .sorting { margin-left: 50px; margin-top: -7px; }
h4 .sorting span { margin-top: 7px; }
h4 .sorting .form-control { width: 120px; margin-left: 10px; }

.btns-list { margin-top: 3px; }
.btns-list .period-selection { margin-right: 50px; font-size: 14px; margin-top: 1px; }
.btns-list .period-selection a { margin: 0 4px; }
.btns-list .period-selection > span { margin: 2px 10px; }

.table-board thead tr.filters th { padding: 1px 2px!important; }
.table-board thead tr.filters th .checkbox { margin: 5px 0 0 0; }
.table-board thead tr.filters th .checkbox input { height: auto; margin-top: 2px; }
.table-board thead tr.filters th .fas { font-size: 10px; width: 12px; text-align: center; }
.table-board thead tr.filters th .far { font-size: 10px; width: 12px; text-align: center; }
.table-board tbody tr td { padding: 5px 5px !important; min-height: 100px; position: relative; }
.table-board tbody tr td.b-r { border-color: #dddddd; }
.table-board tbody tr td .b-r { border-color: #dddddd !important; }
.table-board tbody tr td .b-t { border-color: #dddddd !important; }
.table-board tbody tr td .b-b { border-color: #dddddd !important; }
.table-board tbody tr td.b-r-strong { border-right-width: 2px; border-color: #dddddd; }
.table-board tbody tr td.no-p { padding: 0 !important; }
.table-board tbody tr td a.board-note-daily { display: none; }
.table-board tbody tr td.board-day { cursor: pointer; }
.table-board tbody tr td.board-day:hover { background-color: rgba(0, 0, 0, 0.05); }
.table-board tbody tr td.board-day small { padding: 1px 5px 2px 4px; display: block; background-color: rgba(255, 255, 255, 0.5); border: 1px solid #dddddd; border-radius: 7px; margin-bottom: 2px; position: relative; }
.table-board tbody tr td.board-day small.transport-state { border-left-width: 5px; border-color: rgba(0, 0, 0, 0.1); transition: all 0.5s; }
.table-board tbody tr td.board-day small i.transport-point-type-icon { margin-top: 1px; font-size: 11px; }
.table-board tbody tr td.board-day a { color: #222222; }
.table-board tbody tr td.board-day a small:hover { background-color: rgba(255, 255, 255, 1); border-color: #bbbbbb; }
.table-board tbody tr td.board-day .note { font-size: 10px; font-style: italic; }
.table-board tbody tr td.board-notes { cursor: pointer; padding: 5px 8px; font-size: 10px; font-style: italic; }
.table-board tbody tr td.board-notes:hover { background-color: rgba(0, 0, 0, 0.05); }
.table-board tbody tr td.cursor-default {cursor: default !important; }
.table tbody tr td.is-weekend { background-color: rgba(255, 255, 0, 0.1); }
.table tbody tr td.is-today { background-color: rgba(0, 0, 255, 0.05); }
.table tbody tr td.is-last { background-color: rgba(255, 0, 0, 0.06); }
.table-board tbody tr td .vehicle-state { position: absolute; top: 5px; right: 6px; font-size: 10px; }
.table-board tbody tr td .trailer-state { position: absolute; top: 22px; right: 6px; color: #777777; font-size: 10px; }
.table-board tbody tr td .trailer-state-top { top: 5px; }
.table-board tbody tr td .vehicle-state i, .table-board tbody tr td .trailer-state i { width: 12px; text-align: center; }
.table-board tbody tr td.board-vehicle { background-color: rgba(0, 0, 0, 0.03); }
.table-board tbody tr td.board-notes { background-color: rgba(0, 0, 0, 0.03); border-right: 1px solid #aaaaaa; }
.table-board tbody tr td .transport-point-type-icon { margin-right: 2px; }
.table-board tbody tr td .last-event { font-style: italic; opacity: 0.8; }
.table-board tbody tr td .trailer-current-position { font-style: italic; opacity: 0.75; }
.table-board tbody tr.is-done td { background-color: rgba(0, 128, 0, 0.1); }

.table-board tbody tr td.board-day small.transport-state-1 { border-color: rgba(246, 212, 51, 0.5); }
.table-board tbody tr td.board-day small.transport-state-1:hover { border-color: rgba(246, 212, 51, 1); }

.table-board tbody tr td.board-day small.transport-state-2 { border-color: rgba(18, 175, 203, 0.5); }
.table-board tbody tr td.board-day small.transport-state-2:hover { border-color: rgba(18, 175, 203, 1); }

.table-board tbody tr td.board-day small.transport-state-3 { border-color: rgba(34, 186, 160, 0.5); }
.table-board tbody tr td.board-day small.transport-state-3:hover { border-color: rgba(34, 186, 160, 1); }

.table-board tbody tr td.board-day small.transport-state-4 { border-color: rgba(122, 111, 190, 0.5); }
.table-board tbody tr td.board-day small.transport-state-4:hover { border-color: rgba(122, 111, 190, 1); }

.table-board tbody tr td.board-day small.transport-state-5 { border-color: rgba(242, 86, 86, 0.5); }
.table-board tbody tr td.board-day small.transport-state-5:hover { border-color: rgba(242, 86, 86, 1); }

.table-board tbody tr td.board-day small.transport-state-6 { border-color: rgba(170, 170, 170, 0.5); }
.table-board tbody tr td.board-day small.transport-state-6:hover { border-color: rgba(170, 170, 170, 1); }

.table-board tbody tr td.board-day small.transport-state-7 { border-color: rgba(99, 99, 99, 0.5); }
.table-board tbody tr td.board-day small.transport-state-7:hover { border-color: rgba(99, 99, 99, 1); }
/*
.transport-state-icon-1 { background-color: #f6d433; }
.transport-state-icon-2 { background-color: #12afcb; }
.transport-state-icon-3 { background-color: #22baa0; }
.transport-state-icon-4 { background-color: #7a6fbe; }
.transport-state-icon-5 { background-color: #f25656; }
.transport-state-icon-6 { background-color: #aaaaaa; }
.transport-state-icon-7 { background-color: #636363; }
*/
.detail-tabs { height: 100%; }
.detail-tabs .tab-content { height: calc(100% - 45px); }
.detail-tabs .tab-content .tab-pane { height: 100%; }

.filter-icons { font-size: 11px; }

/* Dot notifications */
tbody.relative-td tr td {position: relative;}
td .dot {
	position: absolute;
	top: 3px;
	right: 3px;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background-color: #777777;
}
td .dot-danger { background-color: #f25656; }
td .dot-success { background-color: #22baa0; }
td .dot-primary { background-color: #7a6fbe; }

/* Variable assignment */
.variable-list { overflow: hidden; width: 69%; float: left; padding-right: 20px; }
.variable-list .variable { border: 1px dashed #777777; background-color: white; padding: 5px 10px; margin-right: 15px; margin-bottom: 15px; cursor: move; float: left; }

.constant-list { width: 30%; float: left; padding-left: 20px; border-left: 1px solid #cccccc; }
.constant-list .constant { border: 1px solid #777777; width: 100%; margin-bottom: 15px; text-align: center; float: left; min-height: 68px; }
.constant-list .constant > span { background-color: #666666; color: #ffffff; width: 100%; display: block; padding: 5px 10px; }
.constant-list .constant > div { padding: 10px 10px 10px 30px; background-color: #dddddd; }

/* Modal */
.slide-pane__header { margin-top: 40px; }
.slide-pane { display: flex; flex-direction: column; background: #fff; width: 400px; height: calc(100% - 40px); transition: transform 0.5s; will-change: transform; pointer-events: all; margin-top: 40px; overflow: scroll; box-shadow: 0 0 5px 0 #aaaaaa; }
.slide-pane:focus { outline-style: none; }
.slide-pane_from_right { margin-left: auto; transform: translateX(100%); }
.slide-pane_from_right.ReactModal__Content--after-open { transform: translateX(0%); }
.slide-pane_from_right.ReactModal__Content--before-close { transform: translateX(100%); }
.slide-pane__overlay { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1000; background-color: rgba(0,0,0,0); pointer-events: none; }

/* Vehicle Messages */
.vehicle-messages-sidebar a { color: #5f5f5f; font-size: 14px; }
.vehicle-messages-sidebar h3 { font-size: 18px; padding: 20px; margin: 0; font-weight: 300; height: 60px; border-bottom: 1px solid #f1f1f1; }
.vehicle-messages-sidebar .search-header .search-input-container { margin-top: -7px; margin-left: 15px; width: 130px; }
.vehicle-messages-sidebar .search-header .search-input-container input { height: 34px; width: 150px; font-size: 16px; }
.vehicle-messages-sidebar small { display: block; opacity: 0.7; }
.vehicle-messages-sidebar .search-results a { padding: 12px 12px; border-bottom: 1px solid #eeeeee; }
.vehicle-messages-sidebar .search-results .vehicle { font-size: 12px; width: 90px; }
.vehicle-messages-sidebar .search-results .vehicle small { font-size: 10px; margin-top: 2px; padding-left: 5px; }
.vehicle-messages-sidebar .search-results .message { font-size: 12px; width: 270px; color: #222222; }
.vehicle-messages-sidebar .search-results .message .icons { margin-top: -5px; margin-bottom: -10px; min-height: 48px; }
.vehicle-messages-sidebar .search-results .message .icons div { margin-top: -2px; }
.vehicle-messages-sidebar .search-results .search-result:hover { background-color: #fbfbfb; }
.vehicle-messages-sidebar .search-results .search-result-unread { background-color: #f8fcff; }
.vehicle-messages-sidebar .search-results .search-result-unread:hover { background-color: #eff7ff; }
.vehicle-messages-sidebar .scrollable { position: absolute; overflow-y: scroll; top: 60px; bottom: 0; }
.vehicle-messages-sidebar .chat { padding-bottom: 120px; }
.vehicle-messages-sidebar .chat small { font-size: 10px; }
.vehicle-messages-sidebar .chat small .far { font-size: 12px; }
.vehicle-messages-sidebar .chat .chat-message { margin-bottom: 2px; padding: 7px 14px; line-height: 20px; max-width: 80%; border-radius: 20px; white-space: pre-line; }
.vehicle-messages-sidebar .chat .chat-item-left small { margin-left: 12px; }
.vehicle-messages-sidebar .chat-write textarea { width: calc(100% - 50px); float: left; }
.vehicle-messages-sidebar .chat-write button { width: 50px; text-align: center; }
.vehicle-messages-sidebar .chat-write button .far { margin: -5px -4px; }

.top-menu .badge { margin-top: -5px; }

.fa-spin.fa-cog {
	transform-origin: 50% 46%;
}

.dropzone { float: left; width: 100%; height: 200px; border: 1px solid #dddddd; cursor: pointer; }
.dropzone:hover { background: #eeeeee; }
.dropzone p { position: absolute; top: 50%; margin-top: -10px; text-align: center; font-size: 20px; width: 100%; color: #888888; }
.dropzone p.accept { display: none; color: #31708f; }
.dropzone-accept { background: #d3e6f0; border-color: #31708f; }
.dropzone-accept p.accept { display: block; }
.dropzone-accept p.ready { display: none; }

.far-fas-hover .fas { display: none; }
.far-fas-hover:hover .far { display: none; }
.far-fas-hover:hover .fas { display: inline-block; }

.accordion-menu > li > ul > li > ul { position: absolute; left: 180px; width: 200px; top: 0!important; }
.accordion-menu > li > ul > li:hover > ul { display: block!important; }

.panel-group .panel-default .panel-heading { background: #dddddd; }
.panel-group .panel-default .panel-collapse { border: 1px solid #dddddd; }

.btn-icon i { margin-right: -13px; }

/** notifications **/
.messages li > div {
    color: #bababa;
    font-size: 13px;
    text-decoration: none;
    display: block;
    position: relative;
    padding: 7px 10px 7px 10px;
    overflow: hidden;
}

.messages li > div .task-icon.badge {
    margin-top: initial;
}

.messages li > div .bold {
    font-weight: bold;
}

.task-icon i {
    margin-left: -2px;
}

.table td.td-menu { padding-left: 3px!important; padding-right: 3px!important; }
.table td.td-menu .btn { padding: 4px 9px; }
.table td.td-menu .btn.far { width: 30px; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
	line-height: 16px;
}

.period-picker-body .tab-content {
	height: auto;
}

.compact-menu .zayml .menu.accordion-menu { background-color: #34425A!important; }
.compact-menu .zayml .menu.accordion-menu > li.droplink:hover > a { background-color: #455270 !important; }
.compact-menu .zayml .menu.accordion-menu ul { background-color: #455270 !important; }
.compact-menu .zayml .menu.accordion-menu > li > a { border-color: #676f79 !important; }
.compact-menu .zayml .menu.accordion-menu .sub-menu li:hover > a { background-color: #34425A!important; }
.compact-menu .zayml .menu.accordion-menu .sub-menu li:hover .sub-menu { background-color: #34425A !important; }
.compact-menu .zayml .menu.accordion-menu .sub-menu li:hover .sub-menu li:hover a { background-color: #455270 !important; }

.compact-menu .sebalex .menu.accordion-menu { background-color: #da5252!important; }
.compact-menu .sebalex .menu.accordion-menu ul { background-color: #a45151 !important; }
.compact-menu .sebalex .menu.accordion-menu > li > a { border-color: #a45151 !important; }
.compact-menu .sebalex .menu.accordion-menu > li.droplink:hover > a { background-color: #a45151 !important; }
.compact-menu .sebalex .menu.accordion-menu .sub-menu li:hover > a { background-color: #bd6361!important; }
.compact-menu .sebalex .menu.accordion-menu .sub-menu li:hover .sub-menu { background-color: #a45151 !important; }
.compact-menu .sebalex .menu.accordion-menu .sub-menu li:hover .sub-menu li:hover a { background-color: #bd6361 !important; }
